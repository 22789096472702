import {CHANGE_CONTENT, FINISH_VIDEO, GET_DISPLAY_CONFIG, SEND_QR} from "../../types";
import {useServices} from "../../services";


export const actGetDisplayConfig = (id, callback) => async dispatch => {
    try {
        const {useDisplayConfigService} = useServices();
        const {get} = useDisplayConfigService();

        const response = await get(id);
        const segments = [...response.data.data.segments];
        let res = {
            ...response.data
        };
        res.data.segments = segments.map((m) => ({
            ...m,
            play: true,
            screenSaver:m.screenSaver,
            config: {
                file: {
                    attributes: {
                        autoPlay: true,
                        muted: false,
                        preload: 'auto'
                    }
                }
            }
        }));

        dispatch({
            type: GET_DISPLAY_CONFIG,
            payload: res,
        });
        if (callback) {
            callback(res);
        }
    } catch (e) {
        console.log(e)
    }
};


export const actSendQR = (data) => async dispatch => {
    try {
        const {useScanQR} = useServices();
        const {post} = useScanQR();

        dispatch({
            type: SEND_QR
        });
        await post(data);

    } catch (e) {
        console.log(e)
    }
};

export const actChangeContent = (content, play) => (dispatch, getState) => {
    const state = getState();
    const {display} = state;
    const aux = {...display};
    const auxData = {...aux.data};
    const auxSegments = [...aux.data.segments];

    const newSegments = auxSegments.map(m => {
        if (m.id === content) {
            return {
                ...m,
                play,
                config: {
                    file: {
                        attributes: {
                            autoPlay: play,
                            muted: false,
                            preload: 'auto'
                        }
                    }
                }
            }
        }
        return m;
    });


    const payload = {
        ...aux,
        data: {
            ...auxData,
            segments: newSegments
        },
        play: content
    };

    dispatch({
        type: CHANGE_CONTENT,
        payload: content
    })

    setTimeout(() => {
        dispatch({
            type: GET_DISPLAY_CONFIG,
            payload: payload
        })
    }, 1000)


};

export const actVideoFinished = (data) => async dispatch => {
    try {
        const {useDisplayVideos} = useServices();
        const {finishVideo} = useDisplayVideos();
        await finishVideo(data);
        dispatch({
            type:FINISH_VIDEO
        })
    } catch (e) {
        console.log(e)
    }
};

