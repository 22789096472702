// Packages
import styled from "styled-components"

import texture from "../../assets/img/textura02.png"

export const StyledBooster = styled.div.attrs({
    className: "StyledBooster",
  })`
      display: flex;
      width: 100%;
      height: 100vh;
      flex-direction: column;
      background: #0071BB;
     
      &:before{
        content:"";
        background-image:url(${texture});
        width: 100%;
        height: 133px;
        background-size: 193px;
        position: absolute;
        bottom: 0;
        z-index: 2;
      }
  `

  export const StyledContent = styled.div.attrs({
    className: "StyledContent",
  })`
  max-width: 850px;
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  `
  export const StyledText = styled.div.attrs({
    className: "StyledText",
  })`
  display: flex;
  &.mb{
    margin-bottom: 2rem;
  }
     svg{
        fill: #fff;
     }
  `
  export const StyledP = styled.p.attrs({
    className: "StyledP",
  })`
  color: #fff;
  font-size: 1.2rem;
  margin: 0 0px 5px 12px;
  font-family: 'FuturaStd-Bold';
  `
  export const StyledValue = styled.p.attrs({
    className: "StyledValue",
  })`
  color: #fff;
  margin: 0 0px 0 12px;
  font-family: 'FuturaStd-Bold';
  &.ot-mar{
    margin: 5px 0px 0 0px;
  }
  `
  export const StyledTotal = styled.div.attrs({
    className: "StyledTotal",
  })`
  width: 49%;
  margin-right: 15%;
  svg{
    fill: #fff;
 }
  `
  export const StyledTextTitle = styled.p.attrs({
    className: "StyledTextTitle",
  })`
  color: #fff;
  font-size: 1.6rem;
  margin: 0;
  font-family: 'FuturaStd-Bold';
  &.small{
    font-size: 1.5rem;
  }
  `
  export const StyledRi = styled.div.attrs({
    className: "StyledRi",
  })`
  width: 100%;
  display: flex;
    flex-direction: column;
    align-items: center;
  `
  export const StyledcontentData = styled.div.attrs({
    className: "StyledcontentData",
  })`
  
  `
  export const StylesTotalContent = styled.div.attrs({
    className: "StylesTotalContent",
  })`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  &.bg-prop{
    background: #E4154C;
    padding: 5px 0px;
  }

  &.col{
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
    p{
        &:first-child{
         width: 100%;
         margin-right: 0%;
        }   
       }
  }
  `
  export const StyledContentBody = styled.div.attrs({
    className: "StyledContentBody",
  })`
  display: flex;
    flex-direction: row;
    margin-top: 2rem;
    align-items: center;
  `
  
  export const StyledImageBrand = styled.img.attrs({
    className: "StyledImageBrand",
  })`
  width: 50%;
  max-width: 255px;
  `
  export const StyledBody = styled.div.attrs({
    className: "StyledBody",
  })`
  padding: 1rem;
  height: 100%;
  position: relative;
  z-index: 2;
  `
  
  
  
  
  
  
  
  