import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  actCloseExp,
  actGetChildren,
  actGetExp,
  actGetFather,
  actPlayOpc,
  actPostContent,
  actResetExp,
  actSetRank,
} from "../../../actions/client";
import { useExpSelector } from "./selector";
import { useClientSelector } from "../home/selector";
import { useListeners } from "../../../socket/listeners";
import { WebSocketContext } from "../../../socket";
import {
  IconBadFace,
  IconGoodFace,
  IconMediumFace,
} from "../../../components/CustomSvgIcons";
import { actGetExpByLang } from "../../../actions/client";

export const useExp = () => {
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { exp, children, lang, getRankText, defaultLang } = useExpSelector();
  const { client } = useClientSelector();
  const { openVideoEvent, onCloseExpEvent } = useListeners(ws.socket);
  const { id } = useParams();
  const timeOut = useRef(0);
  const interval = useRef(0);
  const [time, setTime] = useState(exp.timeout ? exp.timeout : 60);
  const [selectedLan, setSelectedLan] = useState(0);

  const [openRank, setOpenRank] = useState(false);
  const [info, setInfo] = useState(false);
  const [stateQ, setStateQ] = useState([
    { value: 1, state: false, icon: <IconMediumFace /> },
    { value: 2, state: false, icon: <IconBadFace /> },
    { value: 3, state: false, icon: <IconGoodFace /> },
  ]);

  const rankText = useSelector(getRankText(selectedLan));

  const handleClick = (state, index) => {
    const aux = [...stateQ.map((m) => ({ ...m, state: false }))];
    aux[index].state = !state;
    setStateQ(aux);
  };
  const onInfoChange = () => {
    setInfo((i) => !i);
  };
  const showOption = (option, i) => {
    if (option.children) {
      dispatch(actGetChildren(option.children));
      return console.log({ option });
    }
    dispatch(actPlayOpc(i));
    const query = {
      contentId: option.buttonId,
      display: parseInt(id),
      userId: client.id,
    };
    dispatch(
      actPostContent(query, (data) => {
        //const {display, userId} = data;
        if (timeOut.current) {
          resetTimOut();
        }
      })
    );
  };

  const start = () => {};

  const resetTimOut = (callback) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      if (callback) {
        callback();
      }
    }

    if (interval.current) {
      clearInterval(interval.current);
      setTime(exp.timeout ? exp.timeout : 60);
    }
  };

  const closeExp = (isForce) => {
    if (children && children.active) {
      dispatch(actGetFather());
    } else {
      resetTimOut();
      showOption({ buttonId: 0 });
      if (!isForce) {
        dispatch(
          actCloseExp({
            experience_id: parseInt(id),
            device_id: parseInt(client.id),
          })
        );
      }

      return setOpenRank((rank) => !rank);
    }
  };

  const onCloseRank = () => {
    const rank = stateQ.find((f) => f.state);
    if (rank) {
      const dataRank = {
        value: rank.value,
        registration_statu_event_id: client.id,
        content_id: exp.screenId,
        additional_data: info ? 1 : 0,
      };
      dispatch(
        actSetRank(dataRank, () => {
          dispatch(actResetExp());
          history.goBack();
        })
      );
    }
  };

  const onSelectLanguage = (option) => {
    const { value } = option;
    const { id: idLang } = value;
    dispatch(
      actGetExpByLang({ language_id: idLang, visual_module_id: id }, () => {
        setSelectedLan(idLang);
      })
    );
  };

  useEffect(()=>{
  if(defaultLang){
    dispatch(
        actGetExpByLang({ language_id: defaultLang.id, visual_module_id: id }, () => {
          setSelectedLan(defaultLang.id);
        })
    );
  }
  },[defaultLang])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let videoEvent = null;
    if (id) {
      dispatch(
        actGetExp({ id, user_id: client.id }, () => {
          console.log("open channel");
          videoEvent = openVideoEvent((event) => {
            const { message, type } = event;
            if (message && type && type === 2) {
              const { device_id, actType } = message;
              if (
                actType &&
                device_id &&
                device_id === parseInt(client.id) &&
                actType === "videoFinished"
              ) {
                dispatch(actPlayOpc(null, true));
                start();
              }
            }
          });
          if (videoEvent) {
            videoEvent.listener.subscribe();
          }
        })
      );
    }

    return () => {
      if (videoEvent) {
        videoEvent.listener.unsubscribe();
      }
    };
  }, [id, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let closeEvent = null;
    if (id) {
      closeEvent = onCloseExpEvent((e) => {
        console.log({ e });
        if (e && e.message) {
          const { device_id, experience_id } = e.message;
          if (experience_id === parseInt(id) && device_id !== client.id) {
            closeExp();
          }
        }
      });
    }
    return () => {
      if (closeEvent) {
        closeEvent.listener.unsubscribe();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    start();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    exp,
    showOption,
    closeExp,
    children,
    openRank,
    onCloseRank,
    stateQ,
    handleClick,
    onInfoChange,
    canSend: stateQ && stateQ.find((f) => f.state),
    time,
    lang,
    onSelectLanguage,
    rankText,
  };
};
