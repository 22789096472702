// Packages
import styled from "styled-components"

export const StyledBody = styled.div.attrs({
    className: "StyledBody",
  })`
      display: flex;
      width: 100%;
      min-height: 100vh;
      flex-direction: column;
      background: #000;
      //padding-bottom: 4rem;
      position: relative;

      svg{
        margin: 0 auto;
      }

      /* Altura mínima */
@media (max-height: 613px) {
  padding-bottom:3rem;
}
  `

export const StyledContent = styled.div.attrs({
  className: "StyledContent",
})`
    display: flex;
    width: 80%;
    height: auto;
    flex-direction: column;
    border-radius: 5px;
    padding: 1rem;
    margin: 0 auto;
    background-image: linear-gradient(to right top, #04005e, #040062, #040066, #05006b, #05006f);
    margin-top: 2rem;
    min-height: 78vh;
    position: relative;
    svg{
     border: 20px solid #ffffff
     
     }

${(props) => {
    return props.background && `background-color:${props.background}`;
  }}
  
`


export const StyledContentBrand = styled.div.attrs({
    className: "StyledContentBrand",
  })`
  width: 80%;
    
  `

export const Styledh2 = styled.h2.attrs({
    className: "Styledh2",
  })`
  color: #fff;
  font-family: 'FuturaStdLight';
  padding: 0 2px;
  text-align: left;
  margin: 0;
  margin-bottom: 1rem;
  &.mt-12{
    margin-top: 10vh;
  }
  `

  export const StyledMainBrand = styled.img.attrs({
    className: "StyledMainBrand",
  })`
  width: 50%;
  height: auto;
  max-width: 100%;
  `

  export const StyledImageBrand = styled.img.attrs({
    className: "StyledImageBrand",
  })`
  max-width: 255px;
  margin: 20px auto;
  `
  export const StyledHeader = styled.div.attrs({
    className: "StyledHeader",
  })`
    justify-content: center;
    display: flex;
    border-bottom: 2px solid #fff;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: -1rem;
      margin-bottom: -0.6rem;
      border-left: 7px solid #000000;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: -1rem;
      margin-bottom: -0.6rem;
      border-right: 7px solid #000000;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }

  `
  export const StyledContentText = styled.div.attrs({
    className: "StyledContentText",
  })`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 1.3rem 0 2rem 0;
  `

  export const StyledP = styled.div.attrs({
    className: "StyledP",
  })`
  font-family: 'FuturaStdLight';
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  `
  export const StyledImageFooter = styled.img.attrs({
    className: "StyledImageFooter",
  })`
  max-width: 215px;
  margin: 18px auto 0 auto;
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  `

  export const StyledScreens = styled.img.attrs({
    className: "StyledScreens",
  })`
  margin: 18px auto 0 auto;
    width: 90%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  `


