import React from "react";
import { Container, Input, Button, ImgClose } from "./styles";
import { usePassBook } from "./hook";
import icnClose from "../../assets/img/close.png";
import {useParams} from 'react-router-dom';
const PassBook = () => {
  const { email, setEmail, handleGetQR, name, qr, handleClear } = usePassBook();
  const {id} = useParams();
  return (
    <Container>
      {name === "" && qr === "" ? (
        <>
          <h1>Pass Book</h1>
          <Input
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <Button
            onClick={() => {
              handleGetQR({id});
            }}
          >
            Enviar
          </Button>
        </>
      ) : (
        <>
          <ImgClose
            onClick={() => {
              handleClear();
            }}
            src={icnClose}
          />
          <img alt="qr code" src={qr}/>
          <h2>{name}</h2>
        </>
      )}
    </Container>
  );
};

export default PassBook;
