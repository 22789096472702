import {screenChannels, screenEvents, clientChannels, clientEvents} from '../channels';
import {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {CONNECTION} from "../../types";
export const useListeners = (socket) => {
    const dispatch = useDispatch();
    // Screens
    const {screen} = screenChannels;
    const {screenEvent} = screenEvents;
    const openScreenChannel = (callback) => {
        if (socket) {
            const channel = socket.channel(screen);
            channel.subscribe();
            const listener = channel.listen(screenEvent, callback);
            return {
                channel: screen,
                event: screenEvent,
                listener
            }
        }
    };

    // clients
    const {devices} = clientChannels;
    const {deviceChannelEvent, finishedVideoEvent,deviceActiveEvent} = clientEvents;
    const openClientChannel = (callback) => {
        if (socket) {
            const channel = socket.channel(devices);
            channel.subscribe();
            const listener = channel.listen(deviceChannelEvent, callback);
            return {
                channel: devices,
                event: deviceChannelEvent,
                listener
            }
        }
    };

    const openVideoEvent = (callback) => {
        if (socket) {
            const channel = socket.channel(devices);
            channel.subscribe();
            const listener = channel.listen(finishedVideoEvent, callback);
            return {
                channel: devices,
                event: finishedVideoEvent,
                listener
            }
        }
    };

    const onCloseExpEvent = (callback) => {
        if(socket){
            const channel = socket.channel(devices);
            channel.subscribe();
            const listener = channel.listen(deviceActiveEvent, callback);
            return {
                channel: devices,
                event: finishedVideoEvent,
                listener
            }
        }
    };


    useEffect(()=> {
        let pusherConnector = null;
        if(socket){
            pusherConnector = socket.connector.pusher;
            pusherConnector.connection.bind("state_change", function (states) {
                dispatch({
                    type:CONNECTION,
                    payload: states
                })
            });
        }
        return () =>{
            if(pusherConnector){
                pusherConnector.connection.unbind('state_change');
            }
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps



    return {
        openScreenChannel,
        openClientChannel,
        openVideoEvent,
        onCloseExpEvent,
    }


};
