import {feriasAxios} from '../';

export const boosterData = ({event_id}) => {
    return feriasAxios({
        method: 'get',
        url: 'capacity',
        params:{
            event_id
        }
    })
};

export const getRedeemProducts = ({event_id}) => {
    return feriasAxios({
        method: 'get',
        url: `redeemableEvent/${event_id}`,
    });
};

export const postRedeemProducts = ({products,user_id}) =>{
    return feriasAxios({
        method:'post',
        url:'redeemProduct',
        data:{
            products,
            user_id
        }
    });
};

