import {useProviders} from "../../provider";
export const useQr = () => {
    const {getQR} = useProviders();
    const get = (id) =>{

        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await getQR(id))
            }catch (e) {
                reject(e)
            }
        })
    };
    return {
        get
    }
};

export const useExp = () => {
    const {getExp, showContent, closeExp,setRank,checkContentState} = useProviders();
    const get = ({id,user_id}) =>{
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await getExp({id,user_id}))
            }catch (e) {
                reject(e)
            }
        })
    };

    const post = (data) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await showContent(data))
            }catch (e) {
                reject(e)
            }
        })
    };

    const close = (data) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await closeExp(data))
            }catch (e) {
                reject(e)
            }
        })
    };

    const rank = (data) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await setRank(data))
            }catch (e) {
                reject(e)
            }
        })
    };

    const check = (data) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await checkContentState(data))
            }catch (e) {
                reject(e)
            }
        })
    };


    return {
        get,
        post,
        close,
        rank,
        check,
    }
};

export const useClient = () => {
    const {getClient} = useProviders();
    const get = ({user_id}) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await getClient({user_id}))
            }catch (e) {
                reject(e)
            }
        })
    };
    return {
        get
    }
}

