// Packages
import styled from "styled-components"
import tw from "tailwind.macro"

//Iconos
import imageCUstom from "../../assets/img/checbox.png"

export const StyledCheckBox = styled.input.attrs({
  className: "form-checkbox",
})`
  // Default
  ${tw` text-primary-500`}

  &:focus {
    border-color: #785ae6 !important;
    border-width: 1px !important;
  }


`

export const StyledCheckSpan = styled.span`
font-family: 'FuturaStdMedium';
    font-size: 1.4rem;
    color: #fff;
    position: relative;
    left: 0;
    width: 100%;
    padding-left: 3.5rem;
    display: block;
`

export const StyledContentDivChecked = styled.div.attrs({
  className: "StyledContentDivChecked flex flex-col",
})``

export const StyledLabelCheckBox = styled.div.attrs({
  className: "StyledLabelCheckBox inline-flex items-start",
})`
  input {
    height: 2.3rem;
    width: 2.3rem;
    background-size: 74% 80% !important;
    ${tw` border-primary-500 border z-10 absolute`}
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;

    &:checked{
      background-image:url(${imageCUstom});
      background-color:#fff;
    }
  }
`

export const StyledLabel = styled.label``
