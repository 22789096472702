import { feriasAxios } from "../";

export const getUserQr = ({email,id}) => {
  return feriasAxios({
    method: "post",
    url: "userRegisteredToAnEvent",
    data: {
      email,
      event_id:id,
    },
  });
};
