// Packages
import React from "react"
import PropTypes from "prop-types"


// Styled Components
import {
  StyledCheckBox,
  StyledCheckSpan,
  StyledContentDivChecked,
  StyledLabelCheckBox,
} from "./Checkbox.styles"

export const CheckBox = React.forwardRef(
  (
    {
      id,
      className,
      label,
      hideLabel,
      value,
      name,
      onChange,
      indeterminate,
      checked,
      disabled,
      bgChecked,
      rounded,
      size,
      state,
      box,
      light,
      ...props
    },
    ref
  ) => (
    <StyledContentDivChecked>
      <StyledLabelCheckBox
        rounded={rounded}
        size={size}
        state={state}
        box={box}
        light={light}
      >
        <StyledCheckBox
          type="checkbox"
          id={id}
          className={className}
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          value={value}
          bgChecked={bgChecked}
          {...props}
        />
        <StyledCheckSpan disabled={disabled}>{label}</StyledCheckSpan>
      </StyledLabelCheckBox>
    </StyledContentDivChecked>
  )
)

CheckBox.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  hideLabel: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  bgChecked: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "big"]),
}

CheckBox.defaultProps = {
  className: "StyledRadio",
  onChange: () => {},
  hideLabel: true,
  disabled: false,
  label: null,
  rounded: false,
}

export default CheckBox
