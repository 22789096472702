import React, {memo} from "react";
import {useHome} from './hook';
import QRCode from "react-qr-code";
// Assets
import {
    StyledBody,
    StyledContent,
    Styledh2,
    StyledImageBrand,
    StyledHeader,
    StyledContentText,
    StyledP,
    StyledScreens
} from "./home.styles"

import mainBrand from "../../../assets/img/logo.png"

import imageScreens from "../../../assets/img/screens.png"
import SpinnerLoadingIndicator from "../../../components/SpinnerLoading/SpinnerLoading";


const Home = () => {
    const {qr, client, isEventStart, isEventEnd} = useHome();

    const Opc = memo(() => {

        switch (client.status) {
            case 'CHECKIN':
            case 'CHECKOUT':
                return (
                    <>
                        <QRCode
                            value={qr}
                            level={'L'}
                        />
                    </>

                );
            default:
                return (
                    <QRCode value={qr || ''}/>
                );
        }
    });


    return (
        <StyledBody>
            <SpinnerLoadingIndicator isFetching={true}/>
            <StyledContent>
                <StyledHeader>
                    <div>
                        <StyledImageBrand src={mainBrand}/>
                    </div>
                </StyledHeader>

               <StyledContentText>
                <Styledh2>{`${client.name} ${client.lastName}`}</Styledh2>
                <StyledP>{client.country}</StyledP>
               </StyledContentText>

                {isEventStart && !isEventEnd && (
                    <Opc/>
                )}

                {!isEventStart && (
                        <Styledh2 className="mt-12">Próximamente</Styledh2>
                )}

                {isEventStart && isEventEnd && (
                    <Styledh2 className="mt-12">Hasta el próximo evento</Styledh2>
                )}




            </StyledContent>
            <StyledScreens src={imageScreens}/>
        </StyledBody>

    )
};

export default Home;
