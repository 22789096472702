import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/styles.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./redux/store";
import SocketProvider from "./socket";
import {
  Web,
  Display,
  ClientHome,
  UserExp,
  CheckIn,
  CheckOut,
  Booster,
  Redeem,
  PassBook,
} from "./screens";
import { PersistGate } from "redux-persist/integration/react";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store.store}>
      <PersistGate persistor={Store.persistor} loading={false}>
        <Suspense fallback={"Connecting ..."}>
          <SocketProvider>
            <Router>
              <Switch>
                <Route>
                  <Route exact path={"/display/:id"} component={Display} />
                  <Route exact path={"/client/:id"} component={ClientHome} />
                  <Route exact path={"/exp/:id"} component={UserExp} />
                  <Route exact path={"/web"} component={Web} />
                  <Route exact path={"/checkin"} component={CheckIn} />
                  <Route exact path={"/checkout"} component={CheckOut} />
                  <Route exact path={"/booster/:id"} component={Booster} />
                  <Route exact path={"/redeem/:id"} component={Redeem} />
                  <Route exact path={"/forgotPassBook/:id"} component={PassBook} />
                </Route>
              </Switch>
            </Router>
          </SocketProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
