import {useServices} from "../../services";
import {GET_BOOSTER, GET_PRODUCTS} from "../../types";

export const actGetBooster = ({event_id}) => async dispatch => {
  try{
      const {useBooster} = useServices();
      const {get} = useBooster();
      const res = await get({event_id});
      dispatch({
          type:GET_BOOSTER,
          payload: res.data
      })
  }catch (e) {

  }
};
export const actGetRedeemProducts =  ({event_id}) => async dispatch => {
    try{
        const {useBooster} = useServices();
        const {getProducts} = useBooster();
        const res = await getProducts({event_id});
        const {status,data} = res.data;
        const {products,event_id:current_event_id,event} = data;
        dispatch({
            type: GET_PRODUCTS,
            payload: {
                status,
                data:{
                    event_id:current_event_id,
                    event,
                    products:products.map(m => ({...m,count:0,check:false,userCount:0}))

                }
            }
        })
    }catch (e) {
        console.log(e);
    }
};

export const actSetUserProductsToRedeem = ({userProducts}) => (dispatch,getState) => {
    try{
        const {products} = getState();

        const {data,status} = products;
        const {products:currentProducts,event_id:current_event_id,event} = data;
        const redeemProductList = currentProducts.reduce((acc,cv) =>{
            const productFind = userProducts.find(f => f.id === cv.id);
            if(productFind){
                acc.push({
                    ...cv,
                    userCount: (productFind.number - productFind.claimed)
                });
            }else{
                acc.push({...cv})
            }
            return acc;
        },[]);
        dispatch({
            type: GET_PRODUCTS,
            payload:{
                status,
                data:{
                    event_id:current_event_id,
                    event,
                    products:redeemProductList
                }
            }
        })
    }catch (e) {
        console.log(e)
    }
};

export const actRedeemProduct = ({products,user_id},callback) =>async dispatch =>{
    const {useBooster} = useServices();
    const {postRedeemProductService} = useBooster();
    try{
        await postRedeemProductService({products,user_id})
        callback && callback(products);
    }catch (e) {
        console.log(e);
    }
};
