
import {useProviders} from '../../provider';
import {SEND_CHECK_IN, SEND_CHECK_OUT} from "../../types";

export const actCheckIn = (data, callback) => async dispatch =>{
    const {checkIn} = useProviders();
    try{
        dispatch({
            type:SEND_CHECK_IN
        });
        await checkIn(data);
        if(callback){
            callback();
        }
    }catch (e) {

    }
};

export const actCheckOut = (data,callback) =>async dispatch =>{
    const {checkOut} = useProviders();
    try{
        dispatch({
            type:SEND_CHECK_OUT
        });
        await checkOut(data);
        if(callback){
            callback();
        }
    }catch (e) {

    }
};
