import axios from "axios";
import {
  getDisplayConfig,
  sendQrData,
  videoFinished,
  getFile,
  getDisplayByLang,
} from "./display";
import {
  getQR,
  getExp,
  showContent,
  closeExp,
  setRank,
  checkContentState,
  getClient,
} from "./client";
import { checkIn, checkOut } from "./check";
import { boosterData, getRedeemProducts, postRedeemProducts } from "./booster";
import { getUserQr } from "./passBook";
export const feriasAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const useProviders = () => {
  return {
    getDisplayConfig,
    getQR,
    getExp,
    checkIn,
    checkOut,
    boosterData,
    sendQrData,
    showContent,
    closeExp,
    videoFinished,
    getFile,
    setRank,
    checkContentState,
    getRedeemProducts,
    getClient,
    postRedeemProducts,
    getDisplayByLang,
    getUserQr,
  };
};
