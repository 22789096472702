import styled from 'styled-components';
export const Container = styled.div`
    background-color: #000;
    height:${({dimensions}) => `${dimensions.height}px` };
    width:${({dimensions}) => `${dimensions.width}px` };
    display: flex;
    flex-direction: row;
    
`;

export const SegmentDisplay = styled.div`
    background-color: #000;
    height:${({height}) => `${height}px` };
    width:${({width}) => `${width}px` };
`;
