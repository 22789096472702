import React from "react";
import { Container, List, Button, CounterItem, NameProduct, WrapImageProduct, QuantityProduct, Header, ImageLogo, Title, ImageProduct, Input, RegisterButton, WrapUserName, NameUser, IconCheck, CloseModal, NameModal, TextReddem } from "./styles";
import {useRedeem} from "./hook";
import imageLogo from "../../assets/img/logo.png";
import iconCheck from "../../assets/img/check-modal.svg";
import closeModal from "../../assets/img/close-modal.svg";
import Modal from 'react-modal';

const customStyles = {
  content : {
    position: 'relative',
  }
};

const Redeem = () => {
  const {auxProducts, onChangeProduct,onRedeem, event,onSetUser,onChangeInputUser,inputUser, user, onCloseModal,redeemItems,successModal} = useRedeem();
  return (
    <Container>
      <Header>
        <ImageLogo src={imageLogo} />
      </Header>
      <List>
        <Title>{event.event}</Title>
        {auxProducts.map((m,i) => {
          return (
              <li key={i}>
                <WrapImageProduct>
                  <ImageProduct src={m.imagen} />
                </WrapImageProduct>
                <CounterItem>
                  <NameProduct>
                    {m.name}
                  </NameProduct>
                  <Button onClick={()=> onChangeProduct(i,'-')}>
                    -
                  </Button>
                  <QuantityProduct>
                      {`${m.count} / ${m.userCount}`}
                  </QuantityProduct>
                  <Button onClick={()=> onChangeProduct(i,'+')}>
                    +
                  </Button>
                </CounterItem>
              </li>
          )
        })}


        <form onSubmit={onSetUser}>
          <Input value={inputUser} onChange={onChangeInputUser}/>
        </form>


        <RegisterButton
          onClick={onRedeem}
        >
          Redimir
        </RegisterButton>


        <WrapUserName>
          {user && (
            <NameUser>
              {`${user.name} ${user.lastName}`}
            </NameUser>
          )}
        </WrapUserName>

      </List>

      <Modal
        isOpen={ successModal }
        onRequestClose={ onCloseModal }
        style={customStyles}
        className="modal"
        overlayClassName="modal-fondo"
        ariaHideApp={false}
      >
        <CloseModal src={closeModal} onClick={onCloseModal}/>
        <IconCheck src={iconCheck} />
          {user && (
              <NameModal>{`${user.name} ${user.lastName}`}</NameModal>
          )}

          {redeemItems && redeemItems.map(r => (
              <TextReddem>Has redimido <strong>{`${r.count} ${r.name}`}</strong></TextReddem>
          ))}

      </Modal>

    </Container>
  )
}

export default Redeem;
