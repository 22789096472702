import {isEqual} from 'lodash';
import {defaultMemoize,createSelectorCreator} from 'reselect';
export const useReduxTools = () => {
    const createDeepSelector = createSelectorCreator(
        defaultMemoize,
        isEqual
    );

    return {
        createDeepSelector
    }
};
