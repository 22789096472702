import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {actGetRedeemProducts, actRedeemProduct, actSetUserProductsToRedeem} from "../../../actions/booster";
import {actGetClientInfo} from "../../../actions/client";

export const useRedeem = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const products = useSelector(state => state.products.data.products);
    const event = useSelector(state => state.products.data);
    const [auxProducts, setAuxProducts] = useState(products);
    const [inputUser, setInputUser] = useState('');
    const [user, setUser] = useState();
    const [successModal, setSuccessModal] = useState(false);
    const [redeemItems, setRedeemItems] = useState([]);

    useEffect(() => {
        dispatch(actGetRedeemProducts({event_id: id}))
    }, [dispatch, id]);

    useEffect(()=> {
        setAuxProducts(products)
    },[products]);

    const onRedeemSuccess = () =>setSuccessModal(true);

    const onCloseModal = () => window.location.reload();


    const onChangeProduct = (index, operator) => {
        const list = [...auxProducts];
        const product = {...list[index]}
            if(product.count < product.userCount && operator === '+'){
                // eslint-disable-next-line no-eval
                product.count = (eval(`${product.count} ${operator} 1`));
                list[index] = product;
                setAuxProducts(list)
            }else if(product.count > 0 && operator === '-'){
                // eslint-disable-next-line no-eval
                product.count = (eval(`${product.count} ${operator} 1`));
                list[index] = product;
                setAuxProducts(list)
            }
    };

    const onCheck = (index) => {
        const list = [...auxProducts];
        const product = {...list[index]};
        product.check = !product.check;
        list[index] = product;
        setAuxProducts(list)
    };

    const onRedeem = () => {
        const auxRedeemItems = auxProducts.filter(f => f.userCount > 0);
        setRedeemItems(auxRedeemItems);
        const products = auxRedeemItems.map(m => ({product_id:m.id,number: m.count}));
        dispatch(actRedeemProduct({products,user_id:user.id},onRedeemSuccess))
    };

    const onChangeInputUser = (e) => {
        setInputUser(e.target.value)
    };

    const onUserInfo = (info) => {
        const {products} = info;
        dispatch(actSetUserProductsToRedeem({userProducts:products}))
        setUser(info);
    };

    const onSetUser = (e) =>{
        e.preventDefault();
        const auxUser = JSON.parse(inputUser);
        setInputUser('');
        dispatch(actGetClientInfo({user_id:auxUser.id},onUserInfo))
    };



    return {
        auxProducts,
        onChangeProduct,
        onCheck,
        onRedeem,
        event,
        inputUser,
        onChangeInputUser,
        onSetUser,
        user,
        successModal,
        onCloseModal,
        redeemItems

    }
};
