import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  height: 100%;
  width: 100%;
  display: ${(props) => props.isFlex};
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  font-family: "FuturaStdBook";
  .StyledContentDivChecked {
    width: 220px;
    position: relative;
  }
`;

export const Button = styled.div`
  width: 100%;
  background-color: #000;
  height: 100%;
  //min-height: var( --helement1);
  position: relative;

  ${(props) => {
    return props.disabled && `filter: grayscale(0.9);`;
  }}

  ${(props) => {
    return (
      props.play &&
      `
        .StyledPlayImage{
             height: 2rem;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            width: 2rem;

        }
        `
    );
  }}
`;

export const StyledTitle = styled.h2`
  color: ${(props) => props.contrastColor};
  text-align: center;
  font-size: 2rem;
  margin: 2rem 0 0;
  padding: 0 1rem;
  font-weight: 100;
`;

export const StyledText = styled.p`
  color: ${(props) => props.contrastColor};
  text-align: left;
  font-size: 1rem;
  margin: 1rem 0 2rem 0;
  padding: 0 1rem;
`;
export const StyledModeUnique = styled.div`
  padding: 0 1rem;
  // height: calc(95vh - var(--htitle));
  padding-bottom: 1rem;
  // position: absolute;
  // bottom: 0;
  > div {
    border-radius: 5px;
    overflow: hidden;
  }
`;

export const StyledTitles = styled.div`
  width: 100%;
  /* background-color:#000078; */
`;

export const StyledCloseImg = styled.img`
  width: 1.2rem;
  height: 1.2rem;
`;

export const StyledLeftImg = styled.img`
  width: 1.4rem;
  height: 1.4rem;
`;

export const StyledPlayImage = styled.img.attrs({
  className: "StyledPlayImage",
})`
  height: 2rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 2rem;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  //object-fit: cover;
  position: relative;
  //object-position: center;
  display: block;
`;

export const StyledImageUnique = styled.img`
  width: 100%;
  //height: 100%;
  //object-fit: cover;
`;
export const StyledModeZebra = styled.div`
  //height: calc(100vh - var(--htitle));
  display: block;
  overflow-y: auto;
  //grid-template-rows: repeat(auto-fit, minmax(calc(100% / var(--columns)), 1fr));
  position: relative;
`;

export const StyledButtonContainer = styled.div`
  display:flex;
`;

export const StyledButtonLanguage = styled.button`
  background: transparent;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
  width: 45px;
  height: 45px;
  color: white;
  text-shadow: black 0px 0px 5px;
`;

export const StyledContainerLanguageButtons = styled.div`
  display: flex;
`;

export const StyledLangSeparator = styled.span`
  display: inline-block;
  border-left: 2px solid white;
  margin-top: 5px;
  height: 35px;
`;

export const StyledClose = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  z-index: 2;
`;

export const StyledHeader = styled.div`
  position: relative;
`;

//Modal
export const StyledH1 = styled.div`
  font-family: "FuturaStdMedium";
  font-size: 2rem;
  color: #fff;
  text-align: center;
  padding: 0rem 1rem;
`;

export const StyledListExp = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 280px;
  margin: 1rem auto 6.5rem auto;
`;

export const StylesListExp = styled.li`
  ${(props) => {
    return (
      props.state &&
      `
    svg{
        path{
            fill:#fff76b;
        }
    }
    `
    );
  }}
`;
export const StyledButton = styled.button`
  display: block;
  border: 2px solid #fff;
  background: transparent;
  text-transform: uppercase;
  color: #fff;
  font-family: "FuturaStdLight";
  font-size: 1.2rem;
  padding: 0.9rem 1.6rem;
  width: 80%;
  margin: 2rem auto;
`;
export const StyledContentModal = styled.div`
  padding: 0 10rem;
`;
