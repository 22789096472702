export const clientState = {
    status:'',
    data: {
        id: 0,
        name: "",
        lastName: "",
        email: "",
        Qr: {
            name: "",
            last_name: "",
            email: "",
            id: 0,
            token: ""
        }
    }
};

export const clientExp = {
    data: {
        type: 0,
        screenId: 0,
        header:'',
        title: "",
        subTitle: "",
        backgroundColor: "",
        buttons: [],
        languages:[],
        rankingTexts:[],
    },
    defaultLanguage:null
};
export const clientExpChildren = {
    active:false,
    data:{
        buttons:[],
        header:''
    }
};

export const connectionState = {
    current:'',
    previous:''
};
