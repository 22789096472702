import styled from "styled-components";

export const Container = styled.div`
  font-family: "FuturaStdBook";
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`;

export const Button = styled.button`
  margin-bottom: 15px;
  height: 25px;
`;

export const Input = styled.input`
  margin-bottom: 15px;
  height: 25px;
  text-align: center;
  width: 250px;
`;

export const ImgClose = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  padding: 30px;
  cursor pointer
`;
