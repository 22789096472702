import { useServices } from "../../services";

export const actGetUserQr = ({email,id}, callback, callbackError) => async (
  dispatch
) => {
  try {
    const { useGetUserQr } = useServices();
    const { post } = useGetUserQr();

    const response = await post({email,id});
    let res = {
      ...response.data.data[0],
    };

    if (callback) {
      callback(res);
    }
  } catch (error) {
    callbackError();
  }
};
