export const GET_DISPLAY_CONFIG = 'GET_DISPLAY_CONFIG';
export const GET_QR = 'GET_QR';
export const GET_EXP = 'GET_EXP';
export const SEND_CHECK_IN = 'SEND_CHECK_IN';
export const SEND_CHECK_OUT = 'SEND_CHECK_OUT';
export const GET_BOOSTER = 'GET_BOOSTER';
export const SEND_QR = 'SEND_QR';
export const SHOW_CONTENT = 'SHOW_CONTENT';
export const CHANGE_CONTENT = 'CHANGE_CONTENT';
export const RESET_CONTENT = 'RESET_CONTENT';
export const CLOSE_EXP = 'CLOSE_EXP';
export const FINISH_VIDEO = 'FINISH_VIDEO';
export const GET_CHILDREN = 'GET_CHILDREN';
export const GET_FATHER = 'GET_FATHER';
export const SET_RANK = 'SET_RANK';
export const RESET_CHILDREN = 'RESET_CHILDREN';
export const RESET_EXP = 'RESET_EXP';
export const CONNECTION = 'CONNECTION';
export const GET_PRODUCTS = 'GET_PRODUCTS';
