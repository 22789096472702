import {useReduxTools} from "../../../redux/tools";
import {useSelector} from "react-redux";


export const useDisplaySelector = () => {

    const {createDeepSelector} = useReduxTools();

    const displayQuery = createDeepSelector(
        state => state.display,
        display => ({
            ...display.data,
            play: display.play
        })
    );

    const displayConfig = useSelector(displayQuery);

    return {
        displayConfig,
    }
};
