// Packages
import React, {useState, useEffect} from "react"
import {promiseTrackerHoc} from "react-promise-tracker"
import PropTypes from "prop-types"
// Styled components

import Loader from "react-loader-spinner"

// Styles
import {StyledLoader, StyledLoaderContainer, StyledTextContainer,StyledText} from "./SpinnerLoading.styles"
import {useSelector} from "react-redux";
import {actPlayOpc} from "../../actions/client";
import {useDispatch} from 'react-redux';
const SpinnerLoadingIndicator = (props) => {
    const {
        className,
        type,
        color,
        width,
        height,
        promiseInProgress,
    } = props;
    const dispatch = useDispatch();
    const [styledActive, setStyledActive] = useState("auto");
    const connection = useSelector(state => state.connection);
    useEffect(() => {
        document.getElementsByTagName("body")[0].style.overflowY = styledActive;
        setStyledActive(props.promiseInProgress ? "hidden" : "auto")
    }, [props, styledActive])

    useEffect(()=>{
        if(connection && connection.current && connection.previous){
            if(connection.current === 'connected' && connection.previous === 'connecting'){
                dispatch(actPlayOpc(null, true));
            }
        }
    },[connection]); // eslint-disable-line react-hooks/exhaustive-deps

    return connection && connection.current && connection.current !== 'connected' ? (
        <>
            <StyledLoaderContainer/>
            <StyledTextContainer>
              <StyledText>
                <h1 style={{
                    color: '#fff',
                }}>
                    Conexión inestable
                </h1>
                <h2 style={{
                    color: '#f7f6f6',
                }}>
                    {connection.current}
                </h2>
              </StyledText>
            </StyledTextContainer>


        </>
    ) : (
        promiseInProgress && (
            <StyledLoader className={className}>
                <Loader type={type} color={color} height={height} width={width}/>
            </StyledLoader>
        )
    )
}

SpinnerLoadingIndicator.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    isFetching: PropTypes.bool,
    promiseInProgress: PropTypes.bool,
}

SpinnerLoadingIndicator.defaultProps = {
    className: "StyledLoader",
    type: "ThreeDots",
    color: `#fff`,
    width: "100",
    height: "100",
    isFetching: false,
    promiseInProgress: false,
};

export default promiseTrackerHoc(SpinnerLoadingIndicator)
