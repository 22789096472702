import styled from 'styled-components';
import iconCheck from "../../assets/img/check.png";

export const Container = styled.div`
  font-family: "FuturaStdBook";
  padding-bottom: 2rem;
`;

export const Header = styled.div`
  background-color: #020078;
  width: 100%;
  padding: 1rem 0;
`

export const Title = styled.h1`
  color: #020078;
  font-size: 2rem;
  text-align: center;
  margin: 0 0 2rem;
  font-family: 'FuturaStdBold';
`

export const WrapUserName = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  padding: 2.5rem 0;
  margin-top: 2rem;
  border-radius: 0 0 1rem 1rem;
`

export const NameUser = styled.h2`
  color: #020078;
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  font-family: 'FuturaStdBold';
  text-transform: uppercase;
`

export const ImageLogo = styled.img`
  display: block;
  margin: 0 auto;
  height: 2.5rem;
`

export const List = styled.ul`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding-inline-start: 0;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem 0 0;
  margin-top: 2rem;
  box-shadow: 0 0 0 5px rgba(238, 240, 248, 0.5);
  li {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    flex-wrap: wrap;

    input[type="checkbox"] {
      appearance: none;
      width: 1rem;
      height: 1rem;
      border: 1px solid #020078;
      border-radius: 0.25rem;
      cursor: pointer;
      margin-right: 1rem;
      transition: all 0.5s ease;
      &:checked {
        background-color: #020078;
        background-image: url(${iconCheck});
        background-size: 90% auto;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
`

export const CounterItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: 1rem;
  height: 5rem;
  width: 11rem;
`

export const NameProduct = styled.h3 `
  width: 100%;
  margin: 0;
  color: #020078;
  text-transform: uppercase;
  font-size: 1rem;
`

export const QuantityProduct = styled.p `
  margin: 0 0.5rem;
  line-height: 2.5rem;
  color: #4a4a4a;
`

export const WrapImageProduct = styled.div `
  width: 5rem;
  height: 5rem;
  background-color: black;
  position: relative;
`

export const ImageProduct = styled.img `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
`

export const Button = styled.button`
  width: 3rem;
  height: 3rem;
  border: none;
  font-family: 'FuturaStdBold';
  font-size: 1.25rem;
  border-radius: 0.25rem;
  background-color: #e6e6e6;
  color: #4a4a4a;
`
export const RegisterButton = styled(Button)`
  background-color: #020078;
  display: table;
  padding: 0.5rem 2rem;
  width: 15rem;
  color: white;
  font-family: 'FuturaStdBook';
  margin: 0 auto;
  font-size: 0.95rem;
  text-transform: uppercase;
  box-shadow: 0 0 15px 0 rgba(2, 0, 120, 0.35);
`

export const Input = styled.input`
  display: block;
  width: 14rem;
  padding: 0.5rem;
  border: 1px solid #e8e9eb;
  margin: 2rem auto;
  font-size: 1rem;
  font-family: "FuturaStdBook";
`

export const IconCheck = styled.img`
  display: block;
  width: 4rem;
  margin: 0 auto;
`

export const CloseModal = styled.img`
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
`

export const NameModal = styled.p `
  font-size: 1rem;
  color: black;
  font-family: 'FuturaStdBold';
  text-transform: uppercase;
  text-align: center;
  margin: 1rem auto 0;
`

export const TextReddem = styled.p `
  font-size: 1rem;
  color: #4a4a4a;
  text-align: center;
  font-family: "FuturaStdBook";
  margin: 0 auto;
  strong {
    font-family: 'FuturaStdBold';
  }
`