import createReducer from "../create";
import {boosterState,productState} from "./states";
import {GET_BOOSTER, GET_PRODUCTS} from "../../types";

export const booster = createReducer(boosterState,{
    [GET_BOOSTER](state,action){
        return {
            ...state,
            ...action.payload,
        }
    }
});

export const products = createReducer(productState,{
    [GET_PRODUCTS](state,action){
        return {
            ...state,
            ...action.payload
        }
    }
})
