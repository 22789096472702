// Packages
import React from "react"
import PropTypes from "prop-types"

// Styles
import {
  StyledModal,
} from "./Modal.styles"

// Components

export const Modal = (props) => {
  const {
    isOpen,
    handleClose,
    classesDialog,
    children,
  } = props

  

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      classes={classesDialog}
    >
      {children}
    </StyledModal>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  children: PropTypes.any,
}

Modal.defaultProps = {
  className: "StyledModal",
}

export default Modal
