import {useRef,useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {actCheckOut} from '../../actions/check';
export const useCheckIn = () =>{
    const inputRef = useRef(null);
    const [value,setValue] = useState('');
    const dispatch = useDispatch();


    const onChange = (e) =>{
        setValue(e.target.value)
    };

    const handleError = () =>{

    };

    const handleScan = (data) =>{
            if(data){
                setValue(data);
            }
    };

    const sendCheckIn = (e) => {
        e.preventDefault();
        console.log('send')
        if(value){
            const obj = JSON.parse(value);
            dispatch(actCheckOut(obj));
        }
        setValue('');
    };

    useEffect(() => {
        let interval = null;

        if (inputRef.current) {
            inputRef.current.focus();
            interval =  setInterval(()=> {
                inputRef.current.focus();
            },60000)

        }
        return () => {
            if(interval){
                clearInterval(interval);
            }
        }
    }, [inputRef])

    return {
        inputRef,
        handleError,
        handleScan,
        sendCheckIn,
        onChange,
        value
    }


};
