import {feriasAxios} from '../';

export const getDisplayConfig = (visual_module_id) =>{
    return feriasAxios({
        method:'post',
        url:'getScreenDetail',
        data:{
            visual_module_id
        }
    })
};

export const sendQrData = (data) => {
    return feriasAxios({
        method:'post',
        url:'scanCodeExperience',
        data,
    });
};

export const videoFinished = (data) => {
    return feriasAxios({
        method:'post',
        url:'finishedVideo',
        data,
    });
};

export const getFile = (url) =>{
    return feriasAxios({
        method:'get',
        responseType: 'blob',
        url
    })
};

export const getDisplayByLang = ({visual_module_id,language_id}) =>{
    return feriasAxios({
        method:'post',
        url:'getButtonByLanguage',
        data:{
            visual_module_id,
            language_id
        }
    })
}
