import {feriasAxios} from '../';

export const getQR = (user_id) =>{
    return feriasAxios({
        method:'post',
        url:'getClientHome',
        data:{
            user_id: user_id
        }
    })
};

export const getExp = ({id,user_id}) =>{
    return feriasAxios({
        method:'post',
        url:'getClientExp',
        data:{
            exp_id: parseInt(id),
            user_id
        }
    })
};

export const showContent = (data) =>{
    return feriasAxios({
        method:'post',
        url:'videoShow',
        data
    });
}

export const closeExp = (data) =>{
    return feriasAxios({
        method:'post',
        url:'closeChannel',
        data
    });
};
export const setRank = (data) => {
    return feriasAxios({
        method:'post',
        url:'rate',
        data
    });
};

export const checkContentState = (data) => {
    return feriasAxios({
        method:'post',
        url:'closedVideo',
        data
    });
};

export const getClient = ({user_id}) => {
    return feriasAxios({
        method:'post',
        url:'getGeneralInfoUser',
        data:{
            user_id
        }
    });
}

