import React from "react"
import PropTypes from "prop-types"

const IconBadFace= (props) => {
  const { width, height, fill } = props
  return (

<svg viewBox="846.737 129.927 129.622 129.637"  width={width}
      height={height}>
  <path className="cls-1" d="M404.21,129.65A64.82,64.82,0,0,1,358.37,19a64.82,64.82,0,0,1,91.68,91.67,64.4,64.4,0,0,1-45.84,19Zm0-119.52a54.7,54.7,0,1,0,54.69,54.7A54.76,54.76,0,0,0,404.21,10.13Zm25.32,74.19a5.07,5.07,0,0,0-5.07-5.06H384a5.07,5.07,0,1,0,0,10.13h40.51A5.07,5.07,0,0,0,429.53,84.32ZM381.92,42a6.33,6.33,0,1,1-6.33,6.33A6.32,6.32,0,0,1,381.92,42Zm38,6.33A6.33,6.33,0,1,0,426.24,42,6.33,6.33,0,0,0,419.91,48.37Z" fill={fill} transform="matrix(1, 0, 0, 1, 507.347595, 129.893845)"></path>
</svg>
  )
}

IconBadFace.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

IconBadFace.defaultProps = {
  width: "80",
  height: "80",
  fill: "#fff",
}

export default IconBadFace
