import {actGetBooster} from '../../actions/booster';
import {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {useInterval} from "../../utils";
import {useBoosterSelector} from "./selector";
import {useParams} from 'react-router-dom';
export const useBooster = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {boosterData} = useBoosterSelector();

    useInterval(()=> {
        dispatch(actGetBooster({event_id:id}))
    },30000 );

    useEffect(()=> {
        dispatch(actGetBooster({event_id:id}))
    },[dispatch,id])

    return {
        boosterData
    }
};
