import React from "react"
import PropTypes from "prop-types"

const IconGoodFace = (props) => {
  const { width, height, fill } = props
  return (


<svg viewBox="507.367 129.886 129.659 129.678" width={width}
      height={height}>
  <path className="cls-1" d="M64.83,129.65A64.82,64.82,0,0,1,19,19a64.82,64.82,0,1,1,91.67,91.67,64.36,64.36,0,0,1-45.83,19Zm0-119.52a54.7,54.7,0,1,0,54.69,54.7A54.76,54.76,0,0,0,64.83,10.13ZM88.56,76a5.06,5.06,0,0,0-7,1.56c-.06.1-6.37,9.81-17,9.81s-16.93-9.71-17-9.81A5.06,5.06,0,1,0,39,83c.38.59,9.41,14.5,25.54,14.5S89.74,83.6,90.12,83a5.08,5.08,0,0,0-1.56-7Zm-46-34.23a6.33,6.33,0,1,1-6.33,6.33A6.33,6.33,0,0,1,42.54,41.78Zm38,6.33a6.34,6.34,0,1,0,6.34-6.33A6.33,6.33,0,0,0,80.52,48.11Z" fill={fill} transform="matrix(1, 0, 0, 1, 507.347595, 129.893845)"></path>
</svg>
  )
}

IconGoodFace.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

IconGoodFace.defaultProps = {
  width: "80",
  height: "80",
  fill: "#fff",
}

export default IconGoodFace
