import createReducer from "../create";
import {connectionState} from "../client/states";
import {CONNECTION} from "../../types";

export const connection = createReducer(connectionState,{
    [CONNECTION](state,action){
        return {
            ...state,
            ...action.payload,
        }
    },
});
