import React from 'react';
import {useCheckIn} from "./hook";


const CheckIn = () => {
    const {inputRef, sendCheckIn, onChange, value} = useCheckIn();
    return (
        <div>
            <form onSubmit={sendCheckIn}>
                <input
                    style={{

                        height: 200,
                        width: 200,

                    }}
                    ref={(ref) => {
                        inputRef.current = ref
                    }}
                    onChange={onChange}
                    value={value}
                />
            </form>
       {/*     <div>
                <QrReader
                    delay={1000}
                    onError={handleError}
                    onScan={handleScan}
                    style={{width: '50%'}}
                />
            </div>*/}
        </div>
    )
};

export default CheckIn;
