import React, { createContext } from 'react';
import Echo from "laravel-echo";
window.io = require('socket.io-client');
window.Pusher = require('pusher-js');

window.Pusher.logToConsole = false;
const WebSocketContext = createContext(null);

export { WebSocketContext };

const SocketHoc = ({ children }) => {
    let socket,pusher;
    let ws;
    if (!socket && !pusher) {
        try{
            const opc = {
                broadcaster: 'pusher',
                key: 'anyKey',
                cluster: 'us2',
                forceTLS: true,
                enableStats: false,
                activityTimeout:1000,
                pongTimeout:2000,
                wsHost: process.env.REACT_APP_SOCKET_API,
                host:process.env.REACT_APP_SOCKET_API,
                wsPort: 443,
                wssPort:443,
                enabledTransports:['ws','wss'],
                authEndpoint: `${process.env.REACT_APP_API_URL}procolombia/createToken`,
                auth: {
                    params: {
                        email:'test@procolombia.com',
                        password:'@FiturProcolombiaTest'
                    }
                }

            };
            socket = new Echo(opc);

            ws = {
                socket: socket,
            };

        }catch (e) {
            console.log({socketError:e})
        }

    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}
export default SocketHoc;
