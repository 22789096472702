// Packages
import styled from "styled-components"
import tw from "tailwind.macro"

export const StyledLoader = styled.div`
  ${tw`w-full bg-black opacity-50 h-screen fixed z-1 flex justify-center items-center top-0`}
  z-index:1;
`

export const StyledLoaderContainer = styled.div`
  ${tw`w-full h-screen fixed z-1 opacity-50 bg-black`}
  z-index:1;
`;


export const StyledTextContainer = styled.div`
  ${tw`w-full h-screen fixed z-2`}
  z-index:2;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
`;

export const StyledText = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  background-color:rgba(0,0,0,0.6);
  width:100%
`;
