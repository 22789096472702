
import {useReduxTools} from '../../../redux/tools';
import {useSelector} from "react-redux";


export const useExpSelector = () => {

    const {createDeepSelector} = useReduxTools();

    const clientQuery = createDeepSelector(
        state => state.exp.data,
        data => data
    );

    const childrenQuery = createDeepSelector(
        state => state.children,
        children => children
    );

    const languages = createDeepSelector(
        state => state.exp.data,
        data =>{
            return data.languages.map(m =>({ value:m,label:m.name}))
        }
    );

    const defaultLanguage = createDeepSelector(
        state => state.exp.defaultLanguage,
        defaultLang => defaultLang
    )

    const getRankText = (idLang) => createDeepSelector(
        state => state.exp.data,
        data =>{
            const {rankingTexts} = data;
            if(idLang === 0){
                return  rankingTexts[0]
            }
            return rankingTexts.find(f => f.lenguage_id === idLang)
        }
    );
    const children = useSelector(childrenQuery);

    const exp = useSelector(clientQuery);

    const lang = useSelector(languages);

    const defaultLang = useSelector(defaultLanguage)


    return {
        exp,
        children,
        lang,
        getRankText,
        defaultLang,
    }
};



