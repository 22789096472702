// Screens
export const screenChannels = {
    screen: 'screen',
    scanCodeExperience: 'scanCodeExperience'
};

export const screenEvents = {
    screenEvent: 'ScreenEvent',
    scanCodeExperienceEvent: 'ScanCodeExperience'
};

export const clientChannels = {
    devices:'devices',
    closeChannel:'closeChannel'
};

export const clientEvents = {
    deviceChannelEvent:'DeviceChannelEvent',
    closeChannelEvent:'CloseChannelEvent',
    finishedVideoEvent:'finishedVideoEvent',
    deviceActiveEvent:'DeviceActiveEvent',

};
