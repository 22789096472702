import React, {useContext, useEffect, useRef, useState} from "react";
import {WebSocketContext} from "../../socket";

const Web = () => {
    const ws = useContext(WebSocketContext);
    const [positionX, setPositionX] = useState(0)
    const [positionY, setPositionY] = useState(0)
    const containerRef = useRef();
    const [r, setR] = useState([]);
    const [l, setL] = useState([]);
    const [b, setB] = useState([]);
    const [f, setF] = useState([]);

    useEffect(() => {
        const cursor = document.getElementById("cursor");
        cursor.style.left = (positionX) + "px";
        cursor.style.top = (positionY) + "px";


    }, [positionX, positionY]);
    useEffect(() => {
        const {socket} = ws;
        if (socket) {
            socket.on('event://webMouseControl', (data) => {
                const aux = JSON.parse(data);
                if (aux.type && aux.type === 'stop') {
                    if (r.length > 0) {
                        r.forEach(v => {
                            clearInterval(v);
                        })
                    }
                    if (l.length > 0) {
                        l.forEach(v => {
                            clearInterval(v);
                        })
                    }
                    if (b.length > 0) {
                        b.forEach(v => {
                            clearInterval(v);
                        })
                    }
                    if (f.length > 0) {
                        f.forEach(v => {
                            clearInterval(v);
                        })
                    }

                }

                if (aux.type && aux.type === 'move') {
                    switch (aux.direction) {
                        case "RIGHT":
                            const interval = setInterval(() => {
                                setPositionX(x => x + 1);
                            }, 100)
                            const auxR = r;
                            auxR.push(interval);
                            setR(auxR);
                            break
                        case "LEFT":
                            const intervalL = setInterval(() => {
                                setPositionX(x => x - 1);
                            }, 100)
                            const auxL = r;
                            auxL.push(intervalL);
                            setL(auxL);
                            break;
                        case "BACKWARD":
                            const intervalB = setInterval(() => {
                                setPositionY(x => x + 1);
                            }, 100)
                            const auxB = r;
                            auxB.push(intervalB);
                            setB(auxB);
                            break;
                        case "FORWARD":
                            const intervalF = setInterval(() => {
                                setPositionY(x => x - 1);
                            }, 100)
                            const auxF = r;
                            auxF.push(intervalF);
                            setF(auxF);
                            break;
                        default:
                            break;
                    }
                }

            })
        }
    }, [ws]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div style={{
            width: '1000px',
            height: '1000px',
            backgroundColor:'#000'
        }}
             ref={r => {
                 containerRef.current = r;
             }}
        >

            <img id="cursor" alt="img" src=
                "https://media.geeksforgeeks.org/wp-content/uploads/20200319212118/cursor2.png"
                     width="15" height="20"
                     style={{
                         position: 'absolute',
                         pointerEvent: 'none',
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center"
                     }}
            />
            </div>

    )
};

export default Web;
