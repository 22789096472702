import createReducer from "../create";
import {GET_QR, GET_EXP, GET_CHILDREN, GET_FATHER, RESET_CHILDREN, RESET_EXP, SHOW_CONTENT} from "../../types";
import {
    clientState,
    clientExp, clientExpChildren
} from './states';
export const client = createReducer(clientState,{
    [GET_QR](state,action){
        return {
            ...state,
            ...action.payload,
        }
    }
});

export const exp = createReducer(clientExp,{
    [GET_EXP](state,action){
        return {
            ...state,
            ...action.payload,
        }
    },
    [RESET_EXP](state){
        return {
            ...state,
            ...clientExp
        }
    }
});

export const children = createReducer(clientExpChildren,{
    [GET_CHILDREN](state,action){
        return {
            ...state,
            ...action.payload,
            active:true
        }
    },
    [GET_FATHER](state){
        return {
            ...state,
            ...clientExpChildren
        }
    },
    [RESET_CHILDREN](state){
        return{
            ...state,
            ...clientExpChildren
        }
    }
});

export const contentPlaying = createReducer({
    video_show_id: 0
}, {
    [SHOW_CONTENT](state,action){
        return {
            ...state,
            ...action.payload
        }
    }
})
