import React, { useEffect } from "react";
import { useExp } from "./hook";
import {
  Container,
  Button,
  StyledModeUnique,
  StyledImage,
  StyledModeZebra,
  StyledClose,
  StyledCloseImg,
  StyledPlayImage,
  StyledHeader,
  StyledH1,
  StyledContentModal,
  StyledListExp,
  StylesListExp,
  StyledButton,
  StyledLeftImg,
  StyledButtonLanguage,
  StyledLangSeparator,
  StyledButtonContainer,
  StyledContainerLanguageButtons,
} from "./styles";

import icnClose from "../../../assets/img/close.png";
import icnLeft from "../../../assets/img/left.png";
import icnPlay from "../../../assets/img/icn-play.svg";

//Component
import Modal from "../../../components/Modal";
import Checkbox from "../../../components/Checkbox";
import SpinnerLoadingIndicator from "../../../components/SpinnerLoading";

const UserExp = () => {
  const {
    exp,
    showOption,
    closeExp,
    children,
    openRank,
    onCloseRank,
    stateQ,
    handleClick,
    canSend,
    lang,
    onSelectLanguage,
    rankText,
  } = useExp();
  const { background, buttons, type, header } = exp;
  const { data: childrenData, active: childrenActive } = children;

  useEffect(() => {
    let root = document.documentElement;
    buttons.length > 4
      ? root.style.setProperty("--columns", 4)
      : root.style.setProperty("--columns", buttons.length);

    let heiH = document.getElementById("titles").clientHeight;
    if (heiH) {
      root.style.setProperty("--htitle", heiH + "px");
    }

    if (document.getElementById("zebraMode")) {
      let heiButton = document.getElementById("zebraMode").childNodes[0];
      if (heiButton) {
        root.style.setProperty("--helement1", heiButton.clientHeight + "px");
      }
    }
  }, [buttons]);

  return (
    <Container
      backgroundColor={background}
      isFlex={type === 2 ? "flex" : "block"}
    >
      <SpinnerLoadingIndicator isFetching={true} />

      <StyledContainerLanguageButtons>
        {lang.map((item, i) => {
          return (
            <StyledButtonContainer key={`l-${i}`}>
              <StyledButtonLanguage
                onClick={() => {
                  onSelectLanguage(item);
                }}
              >
                {item.value.code}
              </StyledButtonLanguage>
              {i + 1 < lang.length && <StyledLangSeparator />}
            </StyledButtonContainer>
          );
        })}
      </StyledContainerLanguageButtons>

      <StyledClose onClick={closeExp}>
        {childrenActive && <StyledLeftImg src={icnLeft} />}
        {!childrenActive && <StyledCloseImg src={icnClose} />}
      </StyledClose>

      <StyledHeader id="titles">
        {!childrenActive && <StyledImage src={header} />}
        {childrenActive && <StyledImage src={childrenData.header} />}
      </StyledHeader>

      {type === 2 ? (
        <>
          {!childrenActive && (
            <StyledModeZebra id="zebraMode">
              {buttons.map((m, i) => {
                return (
                  <Button
                    key={i.toString()}
                    onClick={() => {
                      if (!m.isDisable) {
                        showOption(m, i);
                      }
                    }}
                    disabled={m.isDisable}
                    play={m.isPlaying}
                  >
                    <StyledImage src={m.buttonImage} />
                    {m.isPlaying && <StyledPlayImage src={icnPlay} />}
                  </Button>
                );
              })}
            </StyledModeZebra>
          )}

          {childrenActive && (
            <StyledModeZebra id="zebraMode">
              {childrenData.buttons.map((m, i) => {
                return (
                  <Button
                    key={i.toString()}
                    onClick={() => {
                      if (!m.isDisable) {
                        showOption(m, i);
                      }
                    }}
                    disabled={m.isDisable}
                    play={m.isPlaying}
                  >
                    <StyledImage src={m.buttonImage} />
                    {m.isPlaying && <StyledPlayImage src={icnPlay} />}
                  </Button>
                );
              })}
            </StyledModeZebra>
          )}
        </>
      ) : (
        <>
          {!childrenActive && (
            <StyledModeUnique id="uniqueMode">
              {buttons.map((m, index) => {
                return (
                  <Button key={`b-${index}`} onClick={() => showOption(m)}>
                    <StyledImage src={m.buttonImage} />
                  </Button>
                );
              })}
            </StyledModeUnique>
          )}

          {childrenActive && (
            <StyledModeUnique id="uniqueMode">
              {childrenData.buttons.map((m, index) => {
                return (
                  <Button key={`b-${index}`} onClick={() => showOption(m)}>
                    <StyledImage src={m.buttonImage} />
                  </Button>
                );
              })}
            </StyledModeUnique>
          )}
        </>
      )}
      <Modal
        isOpen={openRank}
        children={
          <StyledContentModal>
            {rankText && <StyledH1>{rankText.text}</StyledH1>}
            {!rankText && (
              <StyledH1>¿TE GUSTÓ LA EXPERIENCIA QUE ACABAS DE VIVIR?</StyledH1>
            )}

            <StyledListExp>
              {stateQ.map((result, i) => (
                <StylesListExp
                  key={`M-${i}`}
                  state={result.state}
                  onClick={() => handleClick(result.state, i)}
                >
                  {result.icon}
                </StylesListExp>
              ))}
            </StyledListExp>
            <Checkbox
              value={0}
              id={"abi-information"}
              name="abi-information"
              label={
                "Dale clic para recibir más información de esta experiencia"
              }
              state={true}
              onChange={(e) => {}}
            />
            {canSend && (
              <StyledButton onClick={onCloseRank}>Enviar</StyledButton>
            )}
          </StyledContentModal>
        }
      />
    </Container>
  );
};

export default UserExp;
