import {useProviders} from "../../provider";

export const useBooster = () => {
    const {boosterData,getRedeemProducts,postRedeemProducts} = useProviders();
    const get = ({event_id}) =>{
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await boosterData({event_id}))
            }catch (e) {
                reject(e)
            }
        })
    };
    const getProducts = ({event_id}) =>{
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await getRedeemProducts({event_id}))
            }catch (e) {
                reject(e)
            }
        })
    };

    const postRedeemProductService = ({products,user_id}) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await postRedeemProducts({products,user_id}))
            }catch (e) {
                reject(e)
            }
        })
    }
    return {
        get,
        getProducts,
        postRedeemProductService
    }
};

