import { useProviders } from "../../provider";
import { trackPromise } from "react-promise-tracker";

export const useGetUserQr = () => {
  const { getUserQr } = useProviders();

  const post = ({email,id}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getUserQr({email,id})));
      } catch (e) {
        reject(e);
      }
    });
  };

  return { post };
};
