import React from "react";

// Assets
import {
  StyledBooster,
  StyledContent,
  StyledText,
  StyledP,
  StyledValue,
  StyledTextTitle,
  StyledRi,
  StyledcontentData,
  StylesTotalContent,
  StyledContentBody,
  StyledImageBrand,
  StyledBody
} from "./booster.styles"

import IconExit from "./iconExit"
import IconEntry from "./iconEntry"
import mainBrand from "../../assets/img/logo.png"
import {useBooster} from './hook';
import SpinnerLoadingIndicator from "../../components/SpinnerLoading/SpinnerLoading";



const Booster = () => {
    const {boosterData} = useBooster();
    const { capacity,number_participants,check_out,check_in, userCheckIn } = boosterData;
    const {name, surname} = userCheckIn;
  return (
      <StyledBooster>
          <SpinnerLoadingIndicator isFetching={true}/>
         <StyledBody>
            <StyledImageBrand src={mainBrand}/>

            <StyledContent>

        <StylesTotalContent className="bg-prop">
           <StyledTextTitle>{`Aforo ${number_participants}/${capacity} `}</StyledTextTitle>
        </StylesTotalContent>

        <StylesTotalContent className="col">
           <StyledTextTitle className="small">Ultimo ingreso</StyledTextTitle>
           <StyledTextTitle>{`${name} ${surname}`}</StyledTextTitle>
        </StylesTotalContent>

        <StyledContentBody>

           <StyledRi>
           <StyledText className="mb">
              <IconEntry/>
              <StyledcontentData>
                <StyledP>Entradas</StyledP>
                 <StyledValue>{check_in}</StyledValue>
              </StyledcontentData>
           </StyledText>

           <StyledText>

            <IconExit/>
            <StyledcontentData>
            <StyledP>Salidas</StyledP>
             <StyledValue>{check_out}</StyledValue>
              </StyledcontentData>
           </StyledText>
           </StyledRi>
        </StyledContentBody>

         </StyledContent>
         </StyledBody>


      </StyledBooster>
  )
};

export default Booster;
