import { useState } from "react";
import { useDispatch } from "react-redux";
import { actGetUserQr } from "../../../actions/passBook";

export const usePassBook = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [qr, setQr] = useState("");
  const [name, setName] = useState("");

  const handleClear = () => {
    setName("");
    setQr("");
    setEmail("");
  };

  const handleGetQR = ({id}) => {
    dispatch(
      actGetUserQr(
          {email,id},
        (res) => {
          setQr(res.qr);
          setName(`${res.user.name} ${res.user.surname}`);
        },
        () => {
          alert("Usuario no encontrado");
          handleClear();
        }
      )
    );
  };

  return { email, setEmail, handleGetQR, qr, name, handleClear };
};
