import createReducer from "../create";
import {CHANGE_CONTENT, GET_DISPLAY_CONFIG, RESET_CONTENT} from "../../types";
import {
    displayState
} from './states';
export const display = createReducer(displayState,{
    [GET_DISPLAY_CONFIG](state,action){
        return {
            ...state,
            ...action.payload,
        }
    },
    [CHANGE_CONTENT](state,action){
        return {
            ...state,
            play: action.payload,
            data: {...state.data}
        }
    },
    [RESET_CONTENT](state){
        return {
            ...state,
            play: 0,
        }
    }
});
