import React from "react"
import PropTypes from "prop-types"

const IconMediumFace = (props) => {
  const { width, height, fill } = props
  return (
<svg viewBox="677.047 129.886 129.659 129.678"width={width}
      height={height}>
  <path className="cls-1" d="M234.52,129.65A64.82,64.82,0,0,1,188.68,19a64.82,64.82,0,1,1,91.67,91.67,64.36,64.36,0,0,1-45.83,19Zm0-119.52a54.7,54.7,0,1,0,54.69,54.7A54.76,54.76,0,0,0,234.52,10.13Zm25.29,79.58c-.38-.59-9.42-14.5-25.55-14.5s-25.16,13.91-25.54,14.5a5.07,5.07,0,0,0,8.55,5.44c.06-.1,6.36-9.81,17-9.81s16.94,9.71,17,9.81a5.07,5.07,0,0,0,8.55-5.44ZM212.23,41.78a6.33,6.33,0,1,1-6.33,6.33A6.33,6.33,0,0,1,212.23,41.78Zm38,6.33a6.33,6.33,0,1,0,6.33-6.33A6.32,6.32,0,0,0,250.22,48.11Z" fill={fill} transform="matrix(1, 0, 0, 1, 507.347595, 129.893845)"></path>
</svg>
  )
}

IconMediumFace.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

IconMediumFace.defaultProps = {
  width: "80",
  height: "80",
  fill: "#fff",
}

export default IconMediumFace
