import {combineReducers} from 'redux';
import * as display from './display';
import * as client from './client';
import * as booster from './booster';
import * as connection from './connection';

export default combineReducers({
    ...display,
    ...client,
    ...booster,
    ...connection
});
