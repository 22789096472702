import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import Reducers from '../reducer';
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
const initialState = {};
let middleware = [];

const persistConfig = {
    // Root?
    key: "root",
    storage: storage,
    blacklist: [
        'display',
        'children',
        'contentPlaying',
        'connection',
        'products',
        'exp'
    ],
};
const persistReduce = persistReducer(persistConfig, Reducers)
if(process.env.NODE_ENV === 'development'){
    const reduxInmmutableStateInvariant = require("redux-immutable-state-invariant").default();
    middleware = [...middleware, reduxInmmutableStateInvariant, thunk];
}else{
    middleware = [...middleware, thunk];
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let store = createStore(
    persistReduce,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);
let persistor = persistStore(store)
const Store = { store, persistor }

export default Store;
