import {
    GET_QR,
    GET_EXP,
    SHOW_CONTENT,
    CLOSE_EXP,
    GET_CHILDREN,
    GET_FATHER,
    SET_RANK,
    RESET_EXP,
    RESET_CHILDREN
} from "../../types";
import {useServices} from "../../services";



export const actGetQR = (id, callback) => async dispatch => {
    try {
        const {useQr} = useServices();
        const {get} = useQr();

        const response = await get(id);

        dispatch({
            type: GET_QR,
            payload: response.data,
        })

        if(callback){
            callback(response.data)
        }
    } catch (e) {
        console.log(e)
    }
};

export const actGetExp = ({id,user_id}, callback) => async dispatch => {
    try {
        const {useExp} = useServices();
        const {get} = useExp();

        const response = await get({id,user_id});

        let res = {};
        const { data, status } = response.data;

        if(status){
            res.status = status;
        }


        if(data.languages.length){
            res.defaultLanguage = data.languages[0];
        }

        if(data && data.buttons){
            let resData = {
                ...data
            };
            resData.buttons = data.buttons.map( b => ({...b,isPlaying:false, isDisable:false}))
            res.data = resData;
        }

        dispatch({
            type: GET_EXP,
            payload: res,
        })
        if(callback){
            callback(response.data)
        }
    } catch (e) {
        console.log(e)
    }
};
export const actGetExpByLang = ({language_id,visual_module_id},callback) => async (dispatch, getState) => {
    try{
        const state = getState();
        const {exp:{data}} = state;
        const {useDisplayConfigService} = useServices();
        const {getByLangId} = useDisplayConfigService();
        const res = await getByLangId({language_id,visual_module_id});
        const auxExp = {
            ...data,
            ...res.data.data,
        };
        dispatch({
            type: GET_EXP,
            payload: {
                data:auxExp
            },
        })
        callback && callback()
    }catch (e) {
        console.log(e);
    }
};

export const actPlayOpc = (i,reset) => async (dispatch,getState) => {
    const state = getState();
    const {exp, children} = state;
    let newData = {

    };
    if(children.active){
        newData = {
            ...children.data
        }
    }else {
        newData = {
            ...exp.data
        }
    }
    const auxButtons = [...newData.buttons];
    newData.buttons = auxButtons.map((m,index) => {
        if(index === i){
            return {
                ...m,
                isPlaying:true
            }
        }
        if(reset){
            return {
                ...m,
                isDisable:false,
                isPlaying: false,
            }
        }

        return {
            ...m,
            isDisable:true,
            isPlaying: false,
        }
    });

    if(children.active){
        dispatch({
            type: GET_CHILDREN,
            payload: {
                data:newData,
                status:'SUCCESS'
            },
        })
    }else{
        dispatch({
            type: GET_EXP,
            payload: {
                data:newData,
                status:'SUCCESS'
            },
        })
    }

};

export const actPostContent = (data, callback) => async  dispatch => {
  try{
   const {useExp} = useServices();
   const {post} = useExp();
   const res = await post(data);

      dispatch({
          type: SHOW_CONTENT,
          payload: res.data
      });
      if(callback){
          callback(data);
      }
  }catch (e) {
      console.log(e)
  }
};

export const actCloseExp = (data,callback) => async  dispatch => {
    try{
        const {useExp} = useServices();
        const {close} = useExp();
        await close(data);
        dispatch({
            type: CLOSE_EXP
        });
        if(callback){
            callback();
        }
    }catch (e) {
        console.log(e);
    }
};

export const actGetChildren = (data) =>  dispatch => {
      dispatch({
          type: GET_CHILDREN,
          payload:{data}
      })
};

export const actGetFather = () => dispatch => {
  dispatch({
      type:GET_FATHER
  })
};

export const actSetRank = (data, callback) => async dispatch => {
    try{
        const {useExp} = useServices();
        const {rank} = useExp();
        dispatch({
            type: SET_RANK
        });
        await rank(data);
        if(callback){
            callback();
        }
    }catch (e) {
        console.log(e);
    }
};

export const actResetExp = () => dispatch => {
    dispatch({
        type:RESET_EXP
    });
    dispatch({
        type: RESET_CHILDREN
    })
};

export const actCheckContent = (data, callback) => async dispatch => {
    const {useExp} = useServices();
    const {check} = useExp();
  try{
      dispatch({
          type:'CHECK_CONTENT'
      });
        const res =  await check(data);
      if(callback){
          callback(res.data);
      }
  }  catch (e) {

  }
};

export const actGetClientInfo = ({user_id},callback) => async dispatch => {
    try{
        const { useClient } = useServices();
        const {get} = useClient();
        const res = await get({user_id});
        callback && callback(res.data.data);
    }catch (e) {
        console.log(e)
    }
};

