import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  actChangeContent,
  actGetDisplayConfig,
  actSendQR,
  actVideoFinished,
} from "../../../actions/display";
import { useDisplaySelector } from "../selector";
import { WebSocketContext } from "../../../socket";
import { useListeners } from "../../../socket/listeners";

export const useDisplay = () => {
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const inputRef = useRef();
  const { id } = useParams();
  const { displayConfig } = useDisplaySelector();
  const [currentEvent, setCurrentEvent] = useState(null);
  const { openScreenChannel } = useListeners(ws.socket);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const videoShowId = useRef(null);
  const handleScan = (data) => {
    if (data) {
      const user = JSON.parse(data);
      const query = {
        message: {
          ...user,
        },
        experience_id: parseInt(id),
      };
      dispatch(actSendQR(query));
    }
  };
  const handleError = (e) => {
    console.log(e);
  };

  const sendEvent = (e) => {
    e.preventDefault();
    console.log("send");
    if (value) {
      const user = JSON.parse(value);
      const query = {
        message: {
          ...user,
        },
        experience_id: parseInt(id),
      };
      dispatch(actSendQR(query));
    }
    setValue("");
  };
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onEnd = () => {
    console.log("video end", videoShowId.current);
    if (currentEvent) {
      const { message, type } = currentEvent;

      if (message && type && type === 2) {
        const { userId } = message;
        const end = {
          device_id: userId,
          actType: "videoFinished",
          experience_id: parseInt(id),
        };
        if (videoShowId.current) {
          end.video_show_id = videoShowId.current;
        }
        if (userId) {
          dispatch(actVideoFinished(end));
          dispatch(actChangeContent(0, true));
        }
      }
    }
  };

  useEffect(() => {
    let screenChannel = null;
    if (id) {
      dispatch(
        actGetDisplayConfig(id, (res) => {
          console.log("get Display config");
          screenChannel = openScreenChannel((event) => {
            console.log("display event");
            const { message, type } = event;
            setCurrentEvent(event);
            if (
              message &&
              type &&
              type === 2 &&
              message.display === parseInt(id)
            ) {
              const { contentId } = message;
              if (contentId) {
                const { segments } = res.data;
                const active = segments.findIndex((f) => !f.loop);
                if (segments[active]) {
                  if (message.video_show_id) {
                    videoShowId.current = message.video_show_id;
                  }
                  dispatch(actChangeContent(contentId, true));
                }
              }
              if (contentId === 0) {
                dispatch(actChangeContent(0, true));
              }
            }
          });
        })
      );
    }
    return () => {
      if (screenChannel) {
        screenChannel.listener.unsubscribe();
      }
    };
  }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let interval = null;

    if (inputRef.current) {
      inputRef.current.focus();
      interval = setInterval(() => {
        inputRef.current.focus();
      }, 60000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [inputRef]);

  useEffect(() => {
    setTimeout(() => {
      forceUpdate();
    }, 500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    displayConfig,
    handleScan,
    handleError,
    sendEvent,
    inputRef,
    onChange,
    value,
    onEnd,
  };
};
