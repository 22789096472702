import {useContext, useEffect, useRef, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {actCheckContent, actGetQR, actPlayOpc} from '../../../actions/client';
import {useClientSelector} from './selector';
import {useListeners} from "../../../socket/listeners";
import {WebSocketContext} from "../../../socket";
import moment from "moment";
import Store from "../../../redux/store";

export const useHome = () => {
    const ws = useContext(WebSocketContext);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const {client} = useClientSelector();
    const [qr, setQR] = useState('');
    const {openClientChannel,} = useListeners(ws.socket);
    const [isEventStart, setIsEventStart] = useState(false);
    const [isEventEnd, setIsEventEnd] = useState(false);
    const intervalRef = useRef(0);

    const getQrOnEvent = (response, message) =>{
        const {device_id, experience_id} = message;
        const {data} = response;
        if(data && data.id){
            if (device_id && experience_id && device_id === data.id ) {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                history.push(`/exp/${experience_id}`);
            }
        }

    };

    useEffect(() => {
        console.log('open Client Channel')
        const clientChannel = openClientChannel((event) => {
            const {message, type} = event;
            console.log('Client channel event');
            if (type && message && type === 2) {
                const {device_id, experience_id} = message;
                if(client && client.id){
                    if (device_id && experience_id &&  (device_id === params.id || device_id === client.id)) {
                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                        }
                        history.push(`/exp/${experience_id}`);
                    }
                } else {
                    dispatch(actGetQR(params.id, (response)=> getQrOnEvent(response,message)));
                }

            }
        });

        return () => {
            if (clientChannel) {
                clientChannel.listener.unsubscribe();
            }
        };


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const evalEventStart = () => {
        if (client && client.eventDateStart) {
            const dateS = moment(client.eventDateStart);
            if (moment() >= dateS) {
                setIsEventStart(true)
            } else {
                setIsEventStart(false);
            }
        }
    };
    const evalEventEnd = () => {
        if (client && client.eventDateEnd) {
            const dateE = moment(client.eventDateEnd);
            if (moment() > dateE) {
                setIsEventEnd(true)
            } else {
                setIsEventEnd(false)
            }
        }
    };

    useEffect(() => {
        if (params.id) {
            dispatch(actGetQR(params.id));
        }
    }, [params, dispatch]);



    useEffect(() => {
        if (client && client.Qr) {
            const st = JSON.stringify(client.Qr);
            setQR(st)
        }
        evalEventStart();
        evalEventEnd();
    }, [client]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(document){
            document.addEventListener('visibilitychange', (e) => {
                const state = Store.store.getState();
                const {contentPlaying} = state;
                const { video_show_id } = contentPlaying;
                if(video_show_id){
                    dispatch(actCheckContent({ video_show_id }, (data) =>{
                        if(data && data.finishedVideo){
                            dispatch(actPlayOpc(null, true));
                        }
                    }))
                }

            }, false)
        }
    }, [dispatch])
    return {
        qr,
        client,
        isEventStart,
        isEventEnd
    }
};
