import React, { useState } from "react";
import { useDisplay } from "./hook";
import { Container, SegmentDisplay } from "./styles";
import ReactPlayer from "react-player";

const Display = () => {
  const {
    displayConfig,
    sendEvent,
    inputRef,
    onChange,
    value,
    onEnd,
  } = useDisplay();
  const { dimensions, segments, play: activeMedia } = displayConfig;
  const [test, setTest] = useState([]);
  return (
    <Container dimensions={dimensions}>
      {segments.map((item, index) => {
        const { height, width, loop, contents, config } = item;
        let isLoop = loop;
        let media = null;
        let internalConfig = false;
        let screenSaverConfig = {
          file: {
            attributes: {
              autoPlay: true,
              muted: true,
              preload: "auto",
            },
          },
        };
        if (contents.length > 0) {
          if (!loop) {
            const mediaData = contents.find(
              (f) => parseInt(f.id) === parseInt(activeMedia)
            );
            if (mediaData) {
              const { video } = mediaData;
              media = video;
            } else {
              isLoop = true;
              media = item.screenSaver;
              internalConfig = true;
            }
          } else {
            const { video } = contents[0];
            media = video;
          }
        } else {
          isLoop = true;
          media = item.screenSaver;
          internalConfig = true;
        }

        return (
          <SegmentDisplay height={height} width={width} key={index.toString()}>
            <ReactPlayer
              key={index.toString()}
              onEnded={onEnd}
              height={height}
              width="auto"
              loop={isLoop}
              url={media}
              playing={true}
              config={internalConfig ? screenSaverConfig : config}
              onReady={() => {
                const aux = [...test];
                aux[index] = true;
                setTest(aux);
              }}
            />
          </SegmentDisplay>
        );
      })}
      <div
        style={{
          marginTop: 0,
          flexDirection: "row",
          display: "flex",
          position: "absolute",
          opacity: 0,
        }}
      >
        <button
          style={{
            cursor: "pointer",
          }}
          onClick={() => window.location.reload()}
        >
          RELOAD
        </button>
        <form onSubmit={sendEvent}>
          <input
            style={{
              height: 100,
              width: 100,
            }}
            ref={(ref) => {
              inputRef.current = ref;
            }}
            onChange={onChange}
            value={value}
          />
        </form>
      </div>
    </Container>
  );
};

export default Display;
