import React from 'react';
import {useCheckIn} from "./hook";



const CheckIn = () => {
    const {inputRef,sendCheckIn, onChange, value} = useCheckIn();
    return (
        <div>
            <form onSubmit={sendCheckIn} >
            <input
                ref={(ref)=>{ inputRef.current = ref}}
                onChange={onChange}
                value={value}
                style={{
                    width:200,
                    height:200
                }}
            />
            </form>
          {/* <div>
                <QrReader
                    delay={1000}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '50%' }}
                />
            </div>*/}
        </div>
    )
};

export default CheckIn;
