export const displayState = {
    status:'',
    play:0,
    data: {
        event_id:0,
        number_screens: '0',
        own_audio: null,
        ranking: null,
        download_content: null,
        dimensions:{
            height: 0,
            width: 0
        },
        segments: [],

    }
};
