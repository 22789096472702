import {useReduxTools} from '../../redux/tools';
import {useSelector} from "react-redux";

export const useBoosterSelector = () => {
    const {createDeepSelector} = useReduxTools();

    const queryBoosterSelector = createDeepSelector(
        state => state.booster.data,
        data => data
    );
    const boosterData = useSelector(queryBoosterSelector);
    return {
        boosterData
    }

};
