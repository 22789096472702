// Packages
import styled from "styled-components"


export const StyledModal = styled.div.attrs({
  className: "StyledModal",
})`
position: fixed;
width: 100%;
height: 100%;
background-image: linear-gradient(to right top, #04005e, #040062, #040066, #05006b, #05006f);
z-index: 50;
visibility: hidden;
top:0;
${(props) => {
  return props.open && `visibility: visible;`
}}

display: flex;
    justify-content: center;
    align-items: center;
`

