export const boosterState = {
    data:{
        capacity: 0,
        check_in: 0,
        check_out: 0,
        event_id: 0,
        number_participants: 0,
        userCheckIn: {
            name:'',
            surname:''
        },
        userCheckOut: {
            name:'',
            surname:''
        },
    }
};

export const productState = {
    status:'',
    data:{
        event:'',
        event_id:0,
        products:[]
    }
};
