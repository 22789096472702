import {useProviders} from "../../provider";
import { trackPromise } from "react-promise-tracker"


export const useDisplayConfigService = () => {
    const {getDisplayConfig,getDisplayByLang} = useProviders();

    const get = (id) =>{
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await getDisplayConfig(id))
            }catch (e) {
                reject(e)
            }
        })
    };

    const getByLangId = ({visual_module_id,language_id}) => {
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await getDisplayByLang({visual_module_id,language_id}))
            }catch (e) {
                reject(e)
            }
        })
    };

    return {
        get,
        getByLangId
    }
};

export const useScanQR = () => {
    const {sendQrData} = useProviders();
    const post = (data) =>{
        return new Promise(async (resolve, reject)=> {
            try {
                resolve(await trackPromise(sendQrData(data)))
            }catch (e) {
                reject(e)
            }
        })
    };
    return {
        post
    }
};

export const useDisplayVideos = () => {
  const {videoFinished,getFile} = useProviders();

  const preLoadVideo = (url) => {
      return new Promise(async (resolve, reject)=> {
          try {
              resolve(await getFile(url))
          }catch (e) {
              reject(e)
          }
      })
  };

  const finishVideo  = (data) => {
      return new Promise(async (resolve, reject)=> {
          try {
              resolve(await videoFinished(data))
          }catch (e) {
              reject(e)
          }
      })
  };

  return {
      finishVideo,
      preLoadVideo,
  }


};
