import WebScreen from "./webControl";
import DisplayScreen from "./display";
import ClientHomeScreen from "./client/home";
import UserExpScreen from "./client/exp";
import CheckInScreen from "./checkIn";
import CheckOutScreen from "./checkOut";
import BoosterScreen from "./booster";
import RedeemsScreen from "./redeem";
import PassBookScreen from "./passBook";
export const Web = WebScreen;
export const Display = DisplayScreen;
export const ClientHome = ClientHomeScreen;
export const UserExp = UserExpScreen;
export const CheckIn = CheckInScreen;
export const CheckOut = CheckOutScreen;
export const Booster = BoosterScreen;
export const Redeem = RedeemsScreen;
export const PassBook = PassBookScreen;
