import {useReduxTools} from '../../../redux/tools';
import {useSelector} from "react-redux";


export const useClientSelector = () => {

    const {createDeepSelector} = useReduxTools();

    const clientQuery = createDeepSelector(
        state => state.client.data,
        state => state.client.status,
        (data, status) => (
            {
                ...data,
                status
            }
        )
    );

    const client = useSelector(clientQuery);

    return {
        client,
    }
};

