import {
  useDisplayConfigService,
  useScanQR,
  useDisplayVideos,
} from "./display";
import { useQr, useExp, useClient } from "./client";
import { useBooster } from "./booster";
import { useGetUserQr } from "./passBook";

export const useServices = () => {
  return {
    useDisplayConfigService,
    useQr,
    useExp,
    useBooster,
    useScanQR,
    useDisplayVideos,
    useClient,
    useGetUserQr,
  };
};
