import {feriasAxios} from '../';

export const checkIn = (data) =>{
    return feriasAxios({
        method:'post',
        url:'checkin',
        data
    })
};

export const checkOut = (data) =>{
    return feriasAxios({
        method:'post',
        url:'checkOut',
        data
    })
};

